// eslint-disable-next-line no-redeclare,no-unused-vars
function buildHistory() {
  var item = $('#menuHistory').attr({
    'data-bs-toggle': 'collapse',
    'data-bs-target': '#history-collapse',
  });
  var submenuFrame = $(
    '<div id="history-collapse" class="collapse show" data-mdb-perfect-scrollbar="true"></div>'
  )
    .insertAfter(item)
    .css('max-height', 300);
  menuHistory = $('<ul class="sub-menu flex-column gap-1"></ul>').appendTo(
    submenuFrame
  );
  new mdb.PerfectScrollbar(submenuFrame[0], {suppressScrollX: true});

  historyStack = JSON.parse(localStorage.getItem('historyStack'));
  if (historyStack)
    $.each(historyStack, function (i, add) {
      addHistoryToMenu(add);
    });
  else historyStack = [];

  // backward handler
  $(window).on('popstate', function (evt) {
    var i = arraySearchAssoc(historyStack, 'ident', evt.originalEvent.state);
    if (i !== false) {
      var pm = historyStack[i];
      pm.fromState = true;
      ajax(pm);
    }
  });
}
